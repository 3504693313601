
import Link from "next/link"
import React, { } from "react"
import { APPLICATION_ROUTES } from "../../types/routes"
import { MarketingBody, MarketingInviteBarWrapper } from "./MarketingInviteBar.styles"
import { SpecialDiscounts } from "authory-api-types/dist/enums";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";

export enum InviteType {
    REGULAR = "regular",
    BUSINESS = "business",
}

type MarketingInviteBarProps = {
    inviteType?: InviteType
    name?: string
    discount?: SpecialDiscounts
    trialsDays?: number,
    customMessage?: string | JSX.Element;
}

export const MarketingInviteBar = ({ inviteType, name, discount, trialsDays, customMessage }: MarketingInviteBarProps) => {

    if ((!inviteType || !name) && !customMessage && !customMessage?.length) return null;
    return <MarketingInviteBarWrapper >
        <MarketingBody>
            {
                !!customMessage
                    ? <>{customMessage}</>
                    : <>
                        {
                            discount === SpecialDiscounts.Forever30 && <>
                                {name} invited you to try Authory. With this special invite you get 30% off the monthly and yearly plan!
                            </>
                        }
                        {
                            discount === SpecialDiscounts.OneYear20 && <>
                                {name} invited you to try Authory. With this special invite you get 20% off the monthly and yearly plan!
                            </>
                        }
                        {
                            discount === SpecialDiscounts.OneYear30 && <>
                                {name} invited you to try Authory. With this special invite you get 30% off the monthly and yearly plan!
                            </>
                        }
                        {
                            discount === SpecialDiscounts.GWAA && <>
                                {name} invited you to try Authory. With this special invite you get 70% off the yearly plan!
                            </>
                        }
                        {
                            !discount && inviteType === InviteType.REGULAR && <>
                                {name} invited you to try Authory. With this special invite you get to use Authory free for {trialsDays} days!
                            </>
                        }
                        {
                            !discount && !!name?.length && inviteType === InviteType.BUSINESS && <>
                                {
                                    name.startsWith("GWAA") ? <>{name} invited you to Authory. With this special invite you get an Authory account for free.</>
                                        : <>{name} invited you to Authory. With this special invite you get an Authory account for an entire year for free.</>
                                }
                            </>
                        }
                        {" "}
                        {
                            !!name?.length && <Link href={APPLICATION_ROUTES.SIGNUP} passHref legacyBehavior><InlineLink>{!discount && inviteType === InviteType.BUSINESS && name.startsWith("GWAA") ? <>Get started!</> : <>Try it now!</>}</InlineLink></Link>
                        }
                    </>
            }
        </MarketingBody>
    </MarketingInviteBarWrapper>
}