import Script from "next/script";
import { useMock } from "../../tracking/utils/useMock";

export const BeeHiivScript = () => {
  if (useMock()) return <></>;
  return (
    <Script
      id="beehiiv"
      key="beehiiv"
      strategy="lazyOnload"
      type="text/javascript"
      src="https://beehiiv-adnetwork-production.s3.amazonaws.com/pixel-js.js"
    />
  );
};
