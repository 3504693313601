import { ContentType } from "authory-api-types/dist/enums";
import { TypeCounts } from "authory-api-types/dist/types";
import { cloneDeep, omit } from "lodash";
import { FORMATED_SOURCE_TYPES } from "./getFormattedSourceTypes";

export const getSearchFormattedTypesOptions = (typeCounts: TypeCounts) => {
    return cloneDeep(FORMATED_SOURCE_TYPES).filter(sourceType => {
        const types = sourceType.value.split("$");

        if (sourceType.value === "") return true;

        for (const type of types) {
            const userTypeValue = typeCounts[type as ContentType];
            if (userTypeValue && userTypeValue > 0) return true;
        }

        return false;
    }).map(t => omit(t, "icon"));
}