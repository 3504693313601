import styled from "styled-components";

export const DDContentWrapper = styled.div<{ $maxWidth?: number }>`
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 8px 20px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    max-width:  ${({ $maxWidth }) => $maxWidth ? `${$maxWidth}px` : "300px"};
    width: max-content;
    position: relative;
    z-index: 10;
`;

export const DDContentWrapperPadding = styled.div`
    padding: 12px;

    @media print {
        display: none;
    }
`;

export const DDTriggerElem = styled.div`
    &:hover {
        cursor: pointer;
    }
`;