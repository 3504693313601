interface V3IconWebsiteEmailProps extends React.SVGProps<SVGSVGElement> {
  stroke?: string;
}

export const V3IconWebsiteEmail = ({ ...rest }: V3IconWebsiteEmailProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    stroke="#ABB0BA"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
    {...rest}
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
    <path d="M3 9h18M9 21V9" />
  </svg>
);
