import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated, useChain, useSpringRef } from "@react-spring/web";
import { LeftNavBar } from "../../components/NavBar";
import { FadeInContainer } from "../../components/ReusableStyledComponents/FadeIn";
import { V3ContentLargeWrapper } from "../../components/ReusableStyledComponents/V3ContentLargeWrapper";
import { V3ContentSizer } from "../../components/ReusableStyledComponents/V3ContentSizer";
import { V3Footer } from "../../components/V3Footer";
import { V3ModalBase } from "../../components/V3ModalBase";
import { useWindowSize } from "../../hooks/useWindowSize";
import { theme } from "../../theme/theme";
import { APPLICATION_ROUTES } from "../../types/routes";
import Layout from "../Layout";
import {
    SidebarLayoutBottomStickyPanel,
    LeftNavGridContainer,
    LeftNavGridTopBar,
    LeftNavGridLeftBar,
    LeftNavGridMainContent,
    LeftNavNarrowWithPanelContainer,
    SBLLeftPanel
} from "./SidebarLayout.styles";
import { scrollShadowHook } from "../../hooks/scrollShadowHook";
import { VerticalSpacing } from "../../components/VerticalSpacing";
import { useCustomScrollShadows } from "../../hooks/customScrollShadows";

export const SetLeftSideMenuOpen = React.createContext((_: boolean) => {});
export const LeftSideMenuOpen = React.createContext(false);
export const SetFooterVisible = React.createContext((_: boolean) => {});
export const LeftSideMenuBottomPanel = React.createContext<React.MutableRefObject<HTMLDivElement | null> | null>(null);
export const BottomRightSideRefPanel = React.createContext<React.MutableRefObject<HTMLDivElement | null> | null>(null);

interface SidebarLayoutProps {
    title: string;
    mobileMenuTitle?: string;
    leftPanel: JSX.Element;
    rightPanel: JSX.Element;
    searchBarEnabled?: boolean;
    disableFadeIn?: boolean;
    mobileButtons?: boolean;
    footerVisible?: boolean;
    skipAuthorytitle?: boolean;
    showAddcontent?: boolean;
    topNavBarContent: JSX.Element;
    skipContentSpacing?: boolean;
    hideLeftBarDesktop?: boolean;
    animationDisabled?: boolean;
}

export const SidebarLayout = ({
    title,
    mobileMenuTitle,
    topNavBarContent,
    leftPanel,
    rightPanel,
    disableFadeIn = false,
    mobileButtons = false,
    footerVisible = true,
    hideLeftBarDesktop = false,
    skipContentSpacing = false,
    skipAuthorytitle,
    animationDisabled = true
}: SidebarLayoutProps) => {
    const router = useRouter();

    const [menuOpen, setMenuOpen] = useState(false);
    const [ftVisible, setFooterVisible] = useState(footerVisible);

    const size = useWindowSize();
    const isMobile = (size.width && size.width < theme.v3.layout.sidebarLayout.mobile) || false;

    if (size.width && size.width > theme.v3.layout.sidebarLayout.mobile && menuOpen) {
        setMenuOpen(false);
    }

    const bottomPanelRef = useRef<HTMLDivElement | null>(null);
    const bottomRightSideRef = useRef<HTMLDivElement | null>(null);

    const [showShadow] = scrollShadowHook();

    const {
        onScroll,
        ref,
        style: { boxShadow }
    } = useCustomScrollShadows();

    const collScrollRef = useRef();

    useEffect(() => {
        //@ts-ignore
        if (collScrollRef.current && collScrollRef.current.recalculate) collScrollRef.current.recalculate();
    }, [collScrollRef.current]);

    const leftNavSpringRef = useSpringRef();
    const [leftNavAnimationProps] = useSpring(
        () => ({
            ref: leftNavSpringRef,
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: {
                duration: 0
            },
            immediate: animationDisabled
        }),
        []
    );

    const topBarAnimationRef = useSpringRef();
    const [topBarAnimationProps] = useSpring(
        () => ({
            ref: topBarAnimationRef,
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: {
                duration: 0
            },
            immediate: animationDisabled
        }),
        []
    );

    const mainContentAnimationRef = useSpringRef();
    const [mainContentAnimationProps] = useSpring(
        () => ({
            ref: mainContentAnimationRef,
            from: { opacity: 0 },
            to: { opacity: 1 },
            config: {
                duration: 1000
            },
            immediate: animationDisabled
        }),
        []
    );

    const timings = animationDisabled ? undefined : [0, 0, 8];

    useChain([leftNavSpringRef, topBarAnimationRef, mainContentAnimationRef], timings);

    return (
        <Layout title={title} skipAuthorytitle={skipAuthorytitle}>
            <SetLeftSideMenuOpen.Provider value={setMenuOpen}>
                <LeftSideMenuOpen.Provider value={menuOpen}>
                    <LeftSideMenuBottomPanel.Provider value={bottomPanelRef}>
                        <BottomRightSideRefPanel.Provider value={bottomRightSideRef}>
                            <SetFooterVisible.Provider value={setFooterVisible}>
                                <LeftNavGridContainer>
                                    <LeftNavGridTopBar $showShadow={showShadow} data-intercom-target="TopBarSettings">
                                        <animated.div style={topBarAnimationProps}>{topNavBarContent}</animated.div>
                                    </LeftNavGridTopBar>
                                    <LeftNavGridLeftBar>
                                        <animated.div style={leftNavAnimationProps}>
                                            <LeftNavBar animationDisabled={animationDisabled} />
                                        </animated.div>
                                    </LeftNavGridLeftBar>
                                    <LeftNavGridMainContent>
                                        <animated.div style={mainContentAnimationProps}>
                                            <FadeInContainer $disableFadeIn={disableFadeIn}>
                                                <LeftNavNarrowWithPanelContainer $hideLeftBarDesktop={hideLeftBarDesktop}>
                                                    <div>
                                                        {isMobile ? (
                                                            <V3ModalBase
                                                                isOpen={menuOpen}
                                                                onClose={() => setMenuOpen(false)}
                                                                title={mobileMenuTitle}
                                                                bottomPanel={mobileButtons ? <div ref={bottomPanelRef}></div> : undefined}
                                                            >
                                                                {leftPanel}
                                                            </V3ModalBase>
                                                        ) : (
                                                            <SBLLeftPanel
                                                                scrollableNodeProps={{ onScroll, ref }}
                                                                $boxShadow={boxShadow}
                                                                //@ts-ignore
                                                                ref={collScrollRef}
                                                            >
                                                                {leftPanel}
                                                            </SBLLeftPanel>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <FadeInContainer $disableFadeIn={!disableFadeIn}>
                                                            <V3ContentSizer>
                                                                <VerticalSpacing top={skipContentSpacing ? 0 : 30}>{rightPanel}</VerticalSpacing>
                                                                {ftVisible && (
                                                                    <V3ContentLargeWrapper>
                                                                        <V3Footer
                                                                            verticalSpace={router.asPath !== APPLICATION_ROUTES.PORTFOLIO_APP}
                                                                        />
                                                                    </V3ContentLargeWrapper>
                                                                )}
                                                            </V3ContentSizer>
                                                            <SidebarLayoutBottomStickyPanel ref={bottomRightSideRef}></SidebarLayoutBottomStickyPanel>
                                                        </FadeInContainer>
                                                    </div>
                                                </LeftNavNarrowWithPanelContainer>
                                            </FadeInContainer>
                                        </animated.div>
                                    </LeftNavGridMainContent>
                                </LeftNavGridContainer>
                            </SetFooterVisible.Provider>
                        </BottomRightSideRefPanel.Provider>
                    </LeftSideMenuBottomPanel.Provider>
                </LeftSideMenuOpen.Provider>
            </SetLeftSideMenuOpen.Provider>
        </Layout>
    );
};
