import { ContentType } from "authory-api-types/dist/enums";

export const truncate = (str: string, n: number, skipThreeDots = false) => {
    if (str.length <= n) { return str; }
    //trim the string to the maximum length
    let trimmedString = str.substr(0, n);

    //re-trim if we are in the middle of a word and 
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));

    return `${trimmedString}${skipThreeDots ? "" : "..."}`;
};

export const truncateClipWord = (str: string, n: number) => {
    if (str.length <= n) { return str; }

    //trim the string to the maximum length
    let trimmedString = str.substr(0, n);

    return `${trimmedString}...`;
};

export const getContentData = (type: ContentType) => {
    switch (type) {
        case ContentType.Article:
            return { label: "Article" };
        case ContentType.PodcastItem:
            return { label: "Podcast", };
        case ContentType.PdfFile:
            return { label: "PDF" };
        case ContentType.TextPost:
        case ContentType.ImagePost:
        case ContentType.Thread:
        case ContentType.VideoPost:
        case ContentType.ReplyPost:
            return { label: "Post" };
        case ContentType.Video:
            return { label: "Video" };
        case ContentType.Email:
            return { label: "Email" };
        case ContentType.Website:
            return { label: "Website" };
        case ContentType.Image:
            return { label: "Image" };
        case ContentType.Book:
            return { label: "Book" };
    }
}

export const getContentCopyLabel = (type: ContentType) => getContentData(type).label.toLowerCase().replace("pdf", "PDF");