interface V3IconRefreshProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
}

export const V3IconRefresh = ({ stroke = "#2E77FF", strokeWidth = "1.5", ...rest }: V3IconRefreshProps) => (
    <svg width="20" height="21" viewBox="0 0 20 21" {...rest} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.667 9.667A6.75 6.75 0 0 0 3.75 8m-.417-3.333V8h3.334M3.333 11.333A6.75 6.75 0 0 0 16.25 13m.417 3.333V13h-3.334"
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
