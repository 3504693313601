interface V3IconClearFiltersProps extends React.SVGProps<SVGSVGElement> {
    stroke?: string;
}

export const V3IconClearFilters = ({ stroke = "#ABB0BA", strokeWidth = "1.5" }: V3IconClearFiltersProps) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.8 6h6.4M3.2 3h9.6M6.398 9h3.2M7.2 12h1.6"
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="m10 10 4 4M14 10l-4 4" stroke="#fff" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <path d="m10 10 4 4M14 10l-4 4" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
