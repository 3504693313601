import { format } from "date-fns"

export const renderArticleDate = (date: string | null, dateFormat = 'MMM d, yyyy') => {

    if (!date) return "";

    try {

        const newDate = date.substring(0, 10).split('-');

        const splitDate = newDate[0] + '-' + newDate[1] + '-' + newDate[2];

        const dateObject = new Date(splitDate);

        // Ignore the users timezone
        const dtDateOnly = new Date(dateObject.valueOf() + dateObject.getTimezoneOffset() * 60 * 1000);

        return format(dtDateOnly, dateFormat);
    } catch {
        return "";
    }
}