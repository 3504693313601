import ReactModal from "react-modal";
import { useCustomScrollShadows } from "../../hooks/customScrollShadows";
import { V3IconClose } from "../Icons";
import { V3Title } from "../Typography";
import { VerticalSpacing } from "../VerticalSpacing";
import {
    BottomPanelWrapper,
    StyledModal,
    TitleSubTitleWrapper,
    TOP_AND_BOTTOM_MARGIN,
    V3ModalBody,
    V3ModalChildrenWrapper,
    V3ModalCloseWrapper,
    V3ModalLayout,
    V3ModalTitleWrapper
} from "./V3ModalBase.styles";
import { usePreventScroll } from "@react-aria/overlays";
import { useEffect, useState } from "react";
import { throttle } from "lodash";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";

let visualViewport = typeof document !== "undefined" && window.visualViewport;

const getViewportSize = () => {
    return {
        width: (visualViewport && visualViewport?.width) || window.innerWidth,
        height: (visualViewport && visualViewport?.height) || window.innerHeight
    };
};

interface V3ModalBaseProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    subTitle?: string;
    width?: string;
    useHalfContentSplit?: boolean;
    customHalfSplit?: string;
    bottomPanel?: JSX.Element;
    children?: React.ReactNode;
    hideCloseCTA?: boolean;
    useContentBorder?: boolean;
}

export const V3ModalBase: React.FC<React.PropsWithChildren<V3ModalBaseProps>> = ({
    isOpen,
    onClose,
    children,
    title,
    subTitle,
    width,
    useHalfContentSplit,
    customHalfSplit,
    bottomPanel,
    hideCloseCTA = false,
    useContentBorder = false
}) => {
    const hasTitle = !!title?.length;
    const hasSubTitle = !!subTitle?.length;
    const { onScroll, ref, style } = useCustomScrollShadows();
    const [localHeight, setLocalHeight] = useState(0);

    useEffect(() => {
        let onResize = () => {
            const height = getViewportSize().height;
            setLocalHeight(height);
        };

        const throttleResize = throttle(onResize, 100);

        throttleResize();

        if (!visualViewport) {
            window.addEventListener("resize", throttleResize);
        } else {
            visualViewport.addEventListener("resize", throttleResize);
        }

        return () => {
            if (!visualViewport) {
                window.removeEventListener("resize", throttleResize);
            } else {
                visualViewport.removeEventListener("resize", throttleResize);
            }
        };
    }, []);

    usePreventScroll({
        isDisabled: !isOpen
    });

    const modalOverlay: ReactModal.Styles = {
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 10,
            height: localHeight + "px",
            backgroundColor: "rgba(0, 0, 0, 0.45)"
        },
        content: {
            maxHeight: localHeight - 40
        }
    };

    if (localHeight === 0) return <></>;

    return (
        <StyledModal
            isOpen={isOpen}
            onRequestClose={onClose}
            bodyOpenClassName={null}
            style={modalOverlay as ReactModal.Styles}
            ariaHideApp={false}
            width={width}
            useHalfContentSplit={useHalfContentSplit}
            customHalfSplit={customHalfSplit}
        >
            <V3ModalLayout>
                <V3ModalChildrenWrapper ref={ref} onScroll={onScroll} style={style}>
                    <VerticalSpacing bottom={hasTitle ? TOP_AND_BOTTOM_MARGIN : 0} top={TOP_AND_BOTTOM_MARGIN}>
                        <V3ModalTitleWrapper>
                            <TitleSubTitleWrapper>
                                {hasTitle && <V3Title>{title}</V3Title>}
                                {hasSubTitle && <V3BodyGrey48>{subTitle}</V3BodyGrey48>}
                            </TitleSubTitleWrapper>
                            {!hideCloseCTA && (
                                <V3ModalCloseWrapper>
                                    <V3IconClose onClick={onClose} />
                                </V3ModalCloseWrapper>
                            )}
                        </V3ModalTitleWrapper>
                    </VerticalSpacing>
                    <V3ModalBody style={{ marginBottom: bottomPanel ? 0 : TOP_AND_BOTTOM_MARGIN }} $useContentBorder={useContentBorder}>
                        {children}
                    </V3ModalBody>
                </V3ModalChildrenWrapper>
                {!!bottomPanel && <BottomPanelWrapper>{bottomPanel}</BottomPanelWrapper>}
            </V3ModalLayout>
        </StyledModal>
    );
};
