import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
    ArticlePreviewResponse,
    ArticleSearchResultResponse,
    AuthenticatedUserResponse,
    FREE_PLAN_ITEM_LIMIT,
    UserResponse
} from "authory-api-types/dist/types";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3Loader } from "../Loader";
import { InfinitePaginationFooter } from "../InfinitePaginationFooter";
import React from "react";
import { V3NoSearchResults } from "../V3EmptyStateScreen";
import {
    MSCFilterWrapper,
    MSCItemDateSourceWrapper,
    MSCScrollWrapper,
    MSCSourceLabel,
    MSCTitle,
    ManualSelectItemsWraper
} from "./ManualSelectContent.styles";
import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";
import { ItemImage } from "../ItemImage";
import { getItemDisplayTitle } from "authory-api-types/dist/helpers/contentItem";
import { V3BodyGrey48 } from "../ReusableStyledComponents/V3BodyGrey48";
import { renderArticleDate } from "../../utils/articleDateParser";
import { getContentData } from "../../utils/article-text-utils";
import { V3TextInput } from "../TextInput/V3TextInput";
import { V3IconFilter, V3IconMagnifier, V3IconSources } from "../Icons";
import { FormikProps } from "formik";
import { FilterOpt } from "../FilterOpt";
import { getSearchFormattedTypesOptions } from "../../utils/getSearchFormattedTypesOptions";
import { getSearchFormattedSourcesOptions } from "../../utils/getSearchFormattedSourcesOptions";
import { useCustomScrollShadows } from "../../hooks/customScrollShadows";
import { RightAlignPanel } from "../ReusableStyledComponents/RightAlignPanel";
import { ModalLoadingSpinner } from "../ReusableStyledComponents/ModalLoadingSpinner";
import { AuthenticatedUser } from "../../types/user";

interface ManualSelectItemProps {
    item: ArticlePreviewResponse;
    addOrRemoveItem: (add: boolean, canonicalSlug: string) => Promise<void>;
    itemIsActive: boolean;
}

const ManualSelectItem = ({ item, itemIsActive, addOrRemoveItem }: ManualSelectItemProps) => {
    const [loading, setLoading] = useState(false);
    const contentDate = renderArticleDate(item.date, "MMM dd, yyyy");
    return (
        <VerticalSpacing bottom={6} style={{ margin: "0 -10px 6px" }} key={item.slug}>
            <ManualSelectItemsWraper
                key={item.slug}
                onClick={async () => {
                    if (!item.canonicalSlug) return;
                    try {
                        setLoading(true);
                        await addOrRemoveItem(!itemIsActive, item.canonicalSlug);
                        setLoading(false);
                    } catch {
                        setLoading(false);
                    }
                }}
            >
                <ItemImage item={item} />
                <div>
                    <MSCTitle $numLines={2}>{getItemDisplayTitle(item)}</MSCTitle>
                    <MSCItemDateSourceWrapper>
                        {!!contentDate && <V3BodyGrey48 $mobileSmallVariant>{renderArticleDate(item.date, "MMM dd, yyyy")}</V3BodyGrey48>}
                        <MSCSourceLabel $mobileSmallVariant>
                            {getContentData(item.type).label} at <strong>{item.sourceName}</strong>
                        </MSCSourceLabel>
                    </MSCItemDateSourceWrapper>
                </div>
                <div style={{ minWidth: 106, display: "flex", justifyContent: "center" }}>
                    {loading ? (
                        <ModalLoadingSpinner skipLabel />
                    ) : (
                        <V3Button
                            text={itemIsActive ? "Remove" : "Add"}
                            autoWidth
                            color={itemIsActive ? V3ButtonColor.primary : V3ButtonColor.secondary}
                        />
                    )}
                </div>
            </ManualSelectItemsWraper>
        </VerticalSpacing>
    );
};

export type ManualSelectFormik = {
    text?: string;
    source?: string;
    type?: string;
};

interface ManualSelectContentProps {
    items: ArticleSearchResultResponse[];
    itemsLoading: boolean;
    hasNoResults: boolean;
    itemsLoadmore: () => void;
    itemsCanFetchmore: boolean;
    formik: FormikProps<ManualSelectFormik>;
    author: AuthenticatedUserResponse | UserResponse;
    onBackHandler: () => void;
    addOrRemoveItem: (add: boolean, canonicalSlug: string) => Promise<void>;
    getIsItemActive: (item: ArticlePreviewResponse) => boolean;
    numSelectedItems: number;
    confirmSelection?: () => Promise<void>;
}

export const ManualSelectContent = ({
    items,
    itemsLoading,
    hasNoResults,
    itemsLoadmore,
    itemsCanFetchmore,
    formik,
    author,
    onBackHandler,
    addOrRemoveItem,
    getIsItemActive,
    numSelectedItems,
    confirmSelection
}: ManualSelectContentProps) => {
    const [submitting, setSubmitting] = useState(false);
    const {
        onScroll,
        ref,
        style: { boxShadow }
    } = useCustomScrollShadows();

    const allowedSourceTypes = getSearchFormattedTypesOptions(author.typeCounts);

    const sourceFilterOptions = getSearchFormattedSourcesOptions(author);

    const IDLE_STATE = hasNoResults && !itemsLoading;

    const collScrollRef = useRef();

    useEffect(() => {
        //@ts-ignore
        if (collScrollRef.current && collScrollRef.current.recalculate) collScrollRef.current.recalculate();
    }, [collScrollRef.current]);

    return (
        <>
            <MSCFilterWrapper>
                <div>
                    <V3TextInput
                        placeholder={"Search..."}
                        isRounded
                        icon={<V3IconMagnifier onClick={formik.submitForm} />}
                        value={formik.values.text}
                        onChange={formik.handleChange}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                formik.submitForm();
                            }
                        }}
                        name="text"
                    />
                </div>
                <div>
                    <FilterOpt
                        icon={<V3IconFilter />}
                        active={formik.values.type !== undefined}
                        name="type"
                        opts={allowedSourceTypes}
                        value={formik.values.type}
                        formik={formik}
                    />
                </div>
                <div>
                    <FilterOpt
                        icon={<V3IconSources />}
                        active={formik.values.source !== undefined}
                        name={"source"}
                        opts={sourceFilterOptions}
                        value={formik.values.source}
                        formik={formik}
                    />
                </div>
            </MSCFilterWrapper>
            <MSCScrollWrapper
                forceVisible="y"
                autoHide={false}
                scrollableNodeProps={{ onScroll, ref }}
                $boxShadow={boxShadow}
                //@ts-ignore
                ref={collScrollRef}
            >
                {!hasNoResults && (
                    <>
                        {items.map((group, i) => (
                            <React.Fragment key={i}>
                                {group.articles.map((item) => {
                                    if (!item.slug) return;

                                    return (
                                        <ManualSelectItem
                                            key={item.canonicalSlug}
                                            item={item}
                                            itemIsActive={getIsItemActive(item)}
                                            addOrRemoveItem={addOrRemoveItem}
                                        />
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </>
                )}
                {IDLE_STATE && <V3NoSearchResults customPadding={"12px"} />}
                {itemsLoading && (
                    <VerticalSpacing top={32} bottom={32}>
                        <V3Loader justify="center" />
                    </VerticalSpacing>
                )}
                <VerticalSpacing bottom={10}>
                    <InfinitePaginationFooter
                        contentLoading={itemsLoading}
                        numPagesLoaded={items.length}
                        contentCanFetchmore={itemsCanFetchmore}
                        loadMore={itemsLoadmore}
                    />
                </VerticalSpacing>
            </MSCScrollWrapper>
            <VerticalSpacing top={28} style={{ paddingRight: 26 }}>
                <RightAlignPanel>
                    {!!onBackHandler && (
                        <div>
                            <V3Button text="Back" color={V3ButtonColor.secondary} onClick={onBackHandler} disabled={submitting} />
                        </div>
                    )}
                    <div>
                        {submitting ? (
                            <V3Loader height={42} />
                        ) : (
                            <V3Button
                                text={`Continue with ${numSelectedItems} selected items`}
                                autoWidth
                                onClick={async () => {
                                    setSubmitting(true);

                                    try {
                                        if (confirmSelection) await confirmSelection();
                                    } catch {
                                        setSubmitting(false);
                                    }
                                }}
                            />
                        )}
                    </div>
                </RightAlignPanel>
            </VerticalSpacing>
        </>
    );
};

interface ManualSelectContentTrialContainerProps {
    onBackHandler: () => void;
    onCommitFreePlan: (articles?: string[] | undefined) => void;
    user?: AuthenticatedUser;
    formik: FormikProps<ManualSelectFormik>;
    items: ArticleSearchResultResponse[];
    itemsLoading: boolean;
    itemsLoadmore: () => void;
    itemsCanFetchmore: boolean;
    selectedItems: string[];
    setSelectedItems: Dispatch<SetStateAction<string[]>>;
}

export const ManualSelectContentTrialContainer = ({
    user,
    formik,
    items,
    selectedItems,
    setSelectedItems,
    itemsLoading,
    itemsLoadmore,
    itemsCanFetchmore,
    onBackHandler,
    onCommitFreePlan
}: ManualSelectContentTrialContainerProps) => {
    const contentLength = items.length ? items[0].filteredCount : 0;
    const contentCanFetchmore = itemsCanFetchmore || false;
    const HAS_NO_RESULTS = contentLength === 0 && !contentCanFetchmore;

    if (!user) return <></>;

    return (
        <ManualSelectContent
            items={items}
            itemsLoading={itemsLoading}
            hasNoResults={HAS_NO_RESULTS}
            itemsLoadmore={itemsLoadmore}
            itemsCanFetchmore={contentCanFetchmore}
            formik={formik}
            author={user}
            onBackHandler={onBackHandler}
            numSelectedItems={selectedItems.length}
            addOrRemoveItem={async (add, canonicalSlug) => {
                if (selectedItems.length >= FREE_PLAN_ITEM_LIMIT && add) return;

                if (add) {
                    setSelectedItems(selectedItems.concat(canonicalSlug));
                } else {
                    setSelectedItems(selectedItems.filter((it) => it !== canonicalSlug));
                }
            }}
            getIsItemActive={(item: ArticlePreviewResponse) => selectedItems.includes(item.canonicalSlug!)}
            confirmSelection={async () => {
                await onCommitFreePlan(selectedItems);
            }}
        />
    );
};
